import { storyData } from './climate-change';
import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import CardImg1 from '@/assets/img/tmp/building-future/disability_card_img1@2x.jpg';
import CardImg2 from '@/assets/img/tmp/building-future/disability_card_img2@2x.jpg';
import CardImg3 from '@/assets/img/tmp/building-future/disability_card_img3@2x.jpg';
import CardImg4 from '@/assets/img/tmp/building-future/disability_card_img4@2x.jpg';
import CardImg5 from '@/assets/img/tmp/building-future/disability_card_img5@2x.jpg';
import { CampaignData } from '@/components/Card/CampaignCard';
import RelativeBoardCard from '@/components/Card/RelativeBoardCard';
import Container from '@/components/Container';
import Image from '@/components/Image';
import LinkSafe from '@/components/LinkSafe';
import {
  SectionCard,
  SectionStroy,
  SectionTop,
} from '@/components/Nodiscrimination';
import PageComponent from '@/components/PageComponent';
import { SectionNews } from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import SwiperBasic from '@/components/Swiper/SwiperBasic';
import ThumbnailFlex from '@/components/ThumbnailFlex';
import { Tit } from '@/components/Titles';
import YoutubeSingle from '@/components/YoutubeSingle';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';

const card = [
  {
    id: 1,
    title: `포괄적 프로그램 지원`,
    dec: `유니세프는 모든 프로그램에서 장애 어린이를 포괄하여 지원하고 있습니다. 특히, 차별을 유발하는 사회적 규범을 해결하고자 지역사회와 협력하고 아동보호 시스템을 강화합니다. 장애로 인해 신고하기 어려운 환경의 어린이를 포함해 장애 어린이에 대한 학대, 폭력 등을 예방하고 대응할 수 있도록 지원합니다. 장애로 인해 출생신고를 누락하는 일이 없도록 등록 시스템의 접근성을 향상합니다. 또한 장애 어린이가 잠재력을 발휘할 수 있도록 장애 어린이 통합 교육 프로그램을 적용하고 있습니다. 장애 어린이가 이용하기에 불편함이 없는 학습 공간을 제공하고 교사 훈련 및 부모 교육 등을 지원합니다. 또한 장애 어린이에 대한 사회적 낙인으로 인한 차별을 줄이기 위해 다양한 커뮤니케이션 채널을 활용해 인식 개선 캠페인을 진행합니다. 특히 인도주의 위기 상황에서도 장애 어린이가 소외되지 않고 특별한 필요를 충족할 수 있도록 지원합니다.`,
    img: CardImg1,
  },
  {
    id: 2,
    title: `장애 어린이 가족 지원`,
    dec: `장애 어린이를 돌보느라 취업 기회를 잃었거나 추가 생활비로 부담이 큰 가족이 기본적인 생활을 할 수 있도록 지원합니다. 또한 지역사회 기반의 재활 프로그램과 장애 어린이를 둔 가정에 대한 지원을 통해 장애 어린이가 시설이 아닌 가정 내에서 건강하게 자랄 수 있도록 지원합니다.`,
    img: CardImg2,
  },
  {
    id: 3,
    title: `장애 어린이에 대한 자료조사`,
    dec: `장애 어린이에 대한 좁은 식견과 장애를 부끄럽게 여겨 숨기는 태도, 진단법에 대한 투자 부족 등 장애 어린이에 대한 정확한 정보를 수집하기 어렵고, 이는 좋은 정책과 서비스 발전을 더디게 만듭니다. 유니세프는 장애 전문 통계 집단과 협력하여 장애 어린이의 복합요인 조사의 방법론과 다른 자료 조사의 질을 향상하기 위해 노력합니다.`,
    img: CardImg3,
  },
  {
    id: 4,
    title: `차별적 장애 인식 제고`,
    dec: `장애 어린이와 가족이 맞는 가장 큰 장벽은 차별입니다. 평등한 권리 보장을 위해 어린이 보건, 교육, 보호 분야에서 일하는 종사자와 정부 공무원이 장애 어린이에 대한 평등한 인식을 가져야하며, 이를 시작으로 대중 사이에서도 장애에 대한 인식이 제고돼야 합니다. 유니세프는 지역사회와 협력하여 장애에 대한 차별적 인식을 제고하기 위한 인식 개선 캠페인을 진행합니다.`,
    img: CardImg4,
  },
  {
    id: 5,
    title: `협약 비준 및 이행 모니터링`,
    dec: `유엔아동권리협약과 유엔장애인권리협약처럼 장애 어린이의 권리 증진을 위한 협약을 비준하도록 옹호합니다. 비준 이후에는 정부가 협약을 성실하게 이행하는지 모니터링합니다. 이행에 대한 엄격한 관리 감독과 책임 체계를 세우고 이를 적용할 수 있도록 협력합니다. 중앙정부를 비롯해 지방자치단체, 장애인 단체 및 부모 단체 등 모든 분야가 협약 이행에 함께할 수 있도록 협력합니다. 또한 장애 어린이가 장애를 딛고 일어서서 사회의 중심에서 건설적인 변화를 이끌 수 있도록 장애 어린이 관련 의사 결정 과정에 참여시켜 관련 정보를 제공합니다.`,
    img: CardImg5,
  },
];
const Disability: React.FC<PageProps> = ({ location }) => {
  const [slideData, setSlideData] = useState<storyData[]>([]);
  const [thumbs, setThumbs] = useState<CampaignData[]>([]);

  const loadSlideData = useCallback(async () => {
    try {
      const {
        data: childStory,
      } = await BoardCommonControllerService.foListAllUsingGet({
        boardCategoryCode: ['ST09'],
        outDateDispYn: `Y`,
      });
      setSlideData(
        childStory.map((story: BoardVo) => ({
          id: story.boardIndexNumber,
          subject: story.subject,
          contents: story.contents,
          optData1: story.optData1,
          image: story.imgAttGrpNoTn,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  const loadThumbs = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['280', '285', '290'],
        pagePerCount: 8,
        category: ['A020'],
      });
      const articles = data as any;
      setThumbs(
        articles
          .map((article: BoardVo) => ({
            id: article.boardIndexNumber,
            title: article.subject,
            pcImage: article.imgAttGrpNoTn,
            mobileImage: article.imgAttGrpNoTn,
            link: `/what-we-do/news/${article.boardIndexNumber}`,
            date: article.firstRegisterDate,
            boardCategoryCode: article.boardCategoryCode,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadSlideData();
    loadThumbs();
  }, [loadSlideData, loadThumbs]);

  return (
    <LayoutWithTitle
      location={location}
      title="장애 어린이"
      description="for every child, a fair chance"
    >
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  모든 장애 어린이도 충분히 잠재력을 발휘하며{` `}
                  <br className="small-hide" />
                  성장할 수 있는 권리가 있습니다
                </PageComponent>
              </Tit>
            </h2>
            <p className="head-dec">
              <PageComponent id="content1">
                국제 사회의 노력에도 불구하고 수많은 장애 어린이가 여전히
                사회·경제·문화 등 다양한 측면에서 소외되고 있습니다. 장애
                어린이에게 특별한 관심을 기울이지 않는다면 다른 어린이보다 더
                많은 위험에 노출될 수 있습니다. 장애 어린이에게 잠재력을 충분히
                발휘하며 성장할 수 있는 기회를 제공해야 합니다. 유니세프는
                아동권리 기반 접근 방식을 토대로 장애를 포용하고 장애 어린이가
                참여하는 사회를 만들기 위해 노력하고 있습니다.
              </PageComponent>
            </p>
          </SectionHeader>
          <PageComponent id="youtube1">
            <YoutubeSingle videoId="48D8abTh7V0" />
          </PageComponent>
        </Container>
      </SectionTop>

      <SectionCard className="by-sub-main-layout">
        <Container>
          <ThumbnailFlex thumbs={card} />
        </Container>
      </SectionCard>

      <SectionStroy className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">어린이 이야기</Tit>
            </h2>
          </SectionHeader>
        </Container>
        <div className="section-content">
          <div className="obj t1" />
          <div className="obj t2" />
          <Container>
            <div className="content-container">
              <SwiperBasic
                css={`
                  padding: 0;
                `}
              >
                <Swiper slidesPerView="auto" navigation>
                  {slideData.map((row) => (
                    <SwiperSlide key={row.id}>
                      <div className="story-flex">
                        <div className="col-img">
                          <Image pcSrc={row.image} mobileSrc={row.image} />
                        </div>
                        <div className="col-dec">
                          <header>
                            <Tit size="s4" color="white">
                              &ldquo;{row.subject}&rdquo;
                            </Tit>
                            {row.optData1 !== null && row.optData1 !== `` && (
                              <p> - {row.optData1} </p>
                            )}
                          </header>
                          <p className="dec">{row.contents}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </SwiperBasic>
            </div>
          </Container>
        </div>
      </SectionStroy>

      <SectionNews className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">최근 소식</Tit>
              </h2>
              <LinkSafe to="/what-we-do/news?category=A020" className="more">
                <span className="txt">더 보기</span>
              </LinkSafe>
            </div>
          </SectionHeader>
          {thumbs.length > 0 && (
            <RelativeBoardCard
              newslist={thumbs}
              isDate={false}
              isSwiper
              overflowHidden
            />
          )}
        </Container>
      </SectionNews>
    </LayoutWithTitle>
  );
};

export default Disability;
